// Выплывающие элементы при входе на сайт

$(document).ready(function () {
    $navStyle = {
        'margin-top' : 'inherit',
        'opacity' : '1'
    };

    $iceCubeStyle = {
        'top' : '15%',
        'opacity' : '1'
    };

    $downToUp = {
        'margin-top' : 'inherit',
        'opacity' : '1'
    };

    $('.js-logo, .js-nav-item').css($navStyle);
    $('.js-bg-1').css('opacity', '1');
    $('.js-ice-1').css($iceCubeStyle);
    $('.js-cans-area, .js-text-area').css($downToUp);

    setTimeout(function() {
        $('.js-ice-1').addClass('move')
    }, 1000);
});


// Менять стиль навигации по скроллу

$(document).scroll(function () {
    $nav = $(".js-nav");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
});


// Бургер меню

$(document).ready(function () {

    $('.mobile-nav').css('transition', '.8s');

    $('.mobile-burger').click(function () {
        $('.js-burger').toggleClass('active');
        $('.mobile-nav').toggleClass('active');
    });

    $('.js-mob-link').click(function (e) {
        e.preventDefault();

        $('.js-burger').removeClass('active');
        $('.mobile-nav').removeClass('active');

        $('body, html').animate({
            scrollTop: $(this.hash).offset().top - 50
        }, 100);
    });
});




// Плавный скролл

$(document).ready(function () {
    $('.js-link').click(function(e){
        e.preventDefault();
        $('body, html').animate({
            scrollTop: $(this.hash).offset().top - 50
        }, 200);
    });
});



// Параллакс

function parallax() {
    $cans = $('#cans');
    $yPos = window.pageYOffset/40;
    $cans.css('top', $yPos+'%');
}

window.addEventListener("scroll", function(){
    parallax();
});




// Появление элементов по скролингу

$(window).scroll(function() {

    $appearStyle = {
        'transform' : 'translate3d(0,0,0)',
        'opacity' : '1'
    }

    $('.js-show').each(function() {
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css($appearStyle);
            setTimeout(function() {
                $('.js-ice-2').addClass('move')
            }, 2000);
        }
    });

    $('.js-show-lower-cans').each(function() {
        var top_of_element = $(this).offset().top + 250;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css($appearStyle);
            setTimeout(function() {
                $('.js-lower-can').addClass('move')
            }, 1000);
        }
    });



    // Движение картинок по скролу

        // Фотографии

    $('.js-scroll-right').each(function() {
        $speed = $(window).scrollTop()/5;
        $move = $speed - 500;
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css('transform', 'translateX(' + $move + 'px)');

        }
    });
    $('.js-scroll-left').each(function() {
        $speed = $(window).scrollTop()/5;
        $move = $speed - 500;
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css('transform', 'translateX(-' + $move + 'px)');

        }
    });


    // Магазины

    $('.js-scroll-right-logos').each(function() {
        $speed = $(window).scrollTop()/3;
        $move = $speed - 1450;
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css('transform', 'translateX(' + $move + 'px)');

        }
    });
    $('.js-scroll-left-logos').each(function() {
        $speed = $(window).scrollTop()/3;
        $move = $speed - 1450;
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $(this).css('transform', 'translateX(-' + $move + 'px)');
        }
    });

});



// Slick слайдер ромбиков на мобильных устройствах

$(document).ready(function() {
    $('.js-mobile-tiles-slider').slick({
        centerMode: true,
        slidesToShow: 1,
        prevArrow: '<button type="button" class="slick-prev"><</button>',
        nextArrow: '<button type="button" class="slick-next">></button>',
        responsive: [
            {
                breakpoint: 415,
                settings: {
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: 390,
                settings: {
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 330,
                settings: {
                    centerPadding: '10px'
                }
            }
        ]
    });
});


// Slick главный слайдер

$(document).ready(function() {
    $('.main-slider').slick({
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        prevArrow: '<button type="button" class="slick-prev"><</button>',
        nextArrow: '<button type="button" class="slick-next">></button>',
    });
});

// Добавление автопрокрутки после того, как слайдер полностью виден
$(window).scroll(function() {
    var a = $('.main-slider').offset().top;
    var b = $('.main-slider').height();
    var c = window.innerHeight;
    var d = window.scrollY;

    if ((c+d)>(a+b)) {
        $('.main-slider').slick('slickSetOption', {
            autoplay: true
        }, true);
    }
});

// Slick слайдер фотографии на мобильных устройствах

$(document).ready(function() {
    $('.js-mob-photos-slider').slick({
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '50px',
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '<button type="button" class="slick-prev"><</button>',
        nextArrow: '<button type="button" class="slick-next">></button>',
    });
});

// Slick слайдер логотипы на мобильных устройствах

$(document).ready(function() {
    $('.js-mob-logos-slider').slick({
        centerMode: true,
        slidesToShow: 2,
        centerPadding: '30px',
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '<button type="button" class="slick-prev"><</button>',
        nextArrow: '<button type="button" class="slick-next">></button>',
    });
});

// Инициализация видео

$('.js-play').on('click', function() {
    $video = $('#video')[0];
    $video.play();
    $(this).hide();
    $video.controls = true;
});

// Кнопка скролла в топ страницы
$(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {
        $('#return-to-top').fadeIn(200).css('display','block');
    } else {
        $('#return-to-top').fadeOut(200);
    }
});
$('#return-to-top').click(function() {
    $('body,html').animate({
        scrollTop : 0
    }, 500);
});


// Текущий год для футера
$('#year').text(new Date().getFullYear());